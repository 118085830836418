import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const userData = JSON.parse(localStorage.getItem('session'))

const initialState = {
  isAuthenticated: userData ? userData.isAuthenticated : null,
  username: userData ? userData.username : null,
  token: userData ? userData.token : null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMsg: '',
}

const API_URL = process.env.REACT_APP_API_URL

export const login = createAsyncThunk(
  'auth/login',
  async (userData, thunkAPI) => {
    try {
      const { data } = await axios.post(API_URL + '/api/auth/login', userData)

      if (data) {
        localStorage.setItem('session', JSON.stringify(data))
      }

      return data
    } catch (error) {
      console.log(error)
      const message =
        (error.response && error.response.data && error.response.data.msg) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      // clear the user data and set the isAuthenticated flag to false
      localStorage.removeItem('session')
      state.isAuthenticated = false
      state.user = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isAuthenticated = true
        state.username = action.payload.username
        state.token = action.payload.token
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMsg = action.payload
        state.isAuthenticated = false
      })
  },
})

export const { logout } = authSlice.actions

export default authSlice.reducer
