// ** Reducers Imports

import auth from './auth'
import layout from './layout'
import navbar from './navbar'

const rootReducer = {
  auth,
  navbar,
  layout,
}

export default rootReducer
